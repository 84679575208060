import { HashLocationStrategy, LocationStrategy,PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomRouteReuseStrategy } from './custom-reuse-strategy/CustomReuseStrategy';
import { ApiService } from './services/api/api.service';
import { ConfigService } from './services/config/config.service';
import { EmitService } from './services/emit/emit.service';
import { PublicMethodService } from './services/public-method/public-method.service';
import { RequestService } from './services/request/request.service';
import { StorageService } from './services/storage/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdMobileModule } from 'ng-zorro-antd-mobile';
import { TestComponent } from './pages/test/test.component';
import {APP_BASE_HREF} from "@angular/common";
@NgModule({
  declarations: [
    AppComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule, 
    HttpClientModule,
    NgZorroAntdMobileModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }, EmitService, ApiService, RequestService, StorageService, ConfigService, PublicMethodService,{ provide: FormBuilder }, 
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {provide: APP_BASE_HREF, useValue: './'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { } 
