import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
}) 
export class ConfigService {

  constructor() { }
  public mainDomain='https://api.qxb666.com';
  
}
