
import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-test', 
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  text="这是要复制的内容部分7799";


  modal1=false;
  footer = [
    {
      text: 'Ok',
      onPress: () => {
        console.log('ok');
        this.onClose('modal1');
      }
    }
  ];
  footer2 = [
    {
      text: 'Ok',
      onPress: () => {
        console.log('ok');
        this.onClose('modal2');
      }
    }
  ];

  constructor(private clipboardService: ClipboardService) {
      
  }

  ngOnInit(): void {
  }
  copy(){
    this.clipboardService.copy(this.text)
  }

  onClose(key) {
    this.modal1 = false;
  }

  showModal(key) {
    this.modal1 = true;
  }

}
