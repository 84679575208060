import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { RequestService } from '../request/request.service';
import { StorageService } from '../storage/storage.service';
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private storage: StorageService, private request: RequestService, private config: ConfigService) {
  }
  public wx = "/wx/v1/";
 
  //城市列表： regionList 
  public regionList = (params) => this.request.get(`${this.wx}regionList`, params);

  //文章详情： 
  public newsInfo = (params) => this.request.get(`${this.wx}newsInfo/${params['id']}`, params);

  //获取人社局信息 
  public siInfo = (params) => this.request.get(`${this.wx}siInfo/${params['id']}`, params);
  //关闭： 
  // public closemonthModify = (params) => this.request.get(`${this.system}closemonthModify`, params);

  // public adminUserLogin = (params) => this.request.post(`${this.system}AdminUserLogin`, params);
 
  // 查询地址关键词获取坐标列表 
  public placeRegion = (params) => this.request.get(`${this.wx}placeRegion`, params);

  public regeo = (params) => this.request.get(`${this.wx}regeo`, params);

  //服务器定位：
  public locationRegion = (params) => this.request.get(`${this.wx}locationRegion`, params);
}
