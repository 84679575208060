import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { PublicMethodService } from '../public-method/public-method.service';
import { ToastService } from 'ng-zorro-antd-mobile';
@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private _toast: ToastService, private public_method: PublicMethodService, private router: Router, private storage: StorageService, private http: HttpClient, private config: ConfigService) { }
  /**
 * 返回一个obj 对象  Authorization
 */
  getToken() {
    return new HttpHeaders().set("Authorization", !!this.storage.get('Authorization') ? this.storage.get('Authorization') : "");
    // return new HttpHeaders();
  }
  get(url?: any, params?: any) {
    let options = {
      params: params,
      headers: this.getToken()
    }; 
    this._toast.loading('加载中...', 0);
    return new Promise((resolve, rejects) => {
      
      // return; 
      this.http.get(this.config.mainDomain + url, { ...options, responseType: 'json' }).subscribe((res) => {
        this._toast.hide();
        if (res['code'] == 1) {
          resolve(res['data']);
        } else if (res['code'] == -996) {
          this.router.navigateByUrl('/login');
          this.public_method.returnLogin();
        } else { 
          this._toast.show(res['message'], 0);
          setTimeout(() => {
            this._toast.hide(); 
          }, 2000);
          rejects();
        }
      });
    });
  }
  /** 
   * 返回json 格式的obj 对象
   * @param url
   * @param body
   * @param params 
   * @param headers
   */

  post(url, params?: any) {

    // this._toast.show('加载中2~', 0);
    let options = {
      headers: this.getToken()
    };
    this._toast.loading('加载中...', 0);
    return new Promise((resolve, rejects) => {
      this.http.post(this.config.mainDomain + url, params, { ...options }).subscribe((res) => {
        console.log("请求完毕了");
        this._toast.hide();
        if (res['code'] == 1) {
          resolve(res['data']);
        } else if (res['code'] == -996) {
          this.public_method.returnLogin();
        } else {
          this._toast.show(res['message'], 0);
          setTimeout(() => {
            this._toast.hide();
          }, 2000);
          rejects();
        }
      });
    });
  }
  /** 
   * 编辑操作：
   * @param url
   * @param body
   * @param params 
   * @param headers
   */
  put(url, params?: any) {

    // this.message.loading('加载中..');
    let options = {
      headers: this.getToken()
    };
    return new Promise((resolve, rejects) => {
      this.http.put(this.config.mainDomain + url, params, { ...options }).subscribe((res) => {
        // this.message.remove();
        if (res['code'] == 1) {
          resolve(res['data']);
        } else if (res['code'] == -996) {
          this.public_method.returnLogin();
        } else {
          // this.message.create('error', `${res['message']}`);
          // rejects(res['message']);
        }
      });
    });
  }
  //删除操作：
  delete(url) {

    // this.message.loading('加载中..');
    let options = {
      headers: this.getToken()
    };
    return new Promise((resolve, rejects) => {
      this.http.delete(this.config.mainDomain + url, options).subscribe((res) => {
        // this.message.remove();
        if (res['code'] == 1) {
          resolve(res['data']);
        } else if (res['code'] == -996) {
          this.public_method.returnLogin();
        } else {
          // this.message.create('error', `${res['message']}`);
          // rejects(res['message']);
        }
      });
    });
  }

}
