<!-- <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI0NDYxNjU1OQ==&scene=124&uin=&key=&devicetype=Windows+10+x64&version=63030532&lang=zh_CN&a8scene=7&fontgear=2">关注公众号</a>

<br/>

<a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=12345678900==&scene=124#wechat_redirect">测试公众号</a> -->


<div (click)="copy()">点击复制</div>



<!-- <WingBlank> -->
    <WhiteSpace></WhiteSpace>
    <div Button (onClick)="showModal('modal1')">text only</div>
    <WhiteSpace></WhiteSpace>
    <Modal [(ngModel)]="modal1" [transparent]="true" [title]="'Title'" [footer]="footer">
      <div [ngStyle]="{ height: 100, overflow: 'scroll' }">
        scoll content... <br />
        scoll content... <br />
        scoll content... <br />
        scoll content... <br />
        scoll content... <br />
        scoll content... <br />
      </div>
    </Modal>
  <!-- </WingBlank> -->






