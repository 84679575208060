import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponent } from './pages/test/test.component';

const routes: Routes = [
  { path: "gongzhonghao", loadChildren: () => import('./module/gongzhonghao/gongzhonghao.module').then(m => m.GongzhonghaoModule) },
  { path: 'test', component: TestComponent },
  // { path: '**', redirectTo: 'gongzhonghao' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
