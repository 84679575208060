import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // static get(key:any) {
  //   // console.log(key);
  //   return JSON.parse(localStorage.getItem(key));
  // }
  constructor() { }
  set(key:any, value:any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  get(key:any) {
    // console.log("keykeykeykey");
    // console.log(localStorage.getItem(key));
    // console.log("keykeykeykey");
    let tempData=localStorage.getItem(key);
    if(!!tempData&&tempData!='undefined'){
      console.log("11111");
      return JSON.parse(localStorage.getItem(key));
    }else{
      console.log("22222");
      return null;
    }
    
    // return localStorage.getItem(key);
  } 
  remove(key:any) {
    localStorage.removeItem(key);
  }
  getCookie(cname:any) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  }
}
