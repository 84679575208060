import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';

import { EmitService } from '../emit/emit.service';

@Injectable({
  providedIn: 'root'
})

export class PublicMethodService {
  constructor(public emitService: EmitService, private fb: FormBuilder,  private storage: StorageService, private router: Router) { }


  // 日期格式化
  dateFormat(fmt = 'YYYY-mm-dd', date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  }
  //循环数组获取对应的下标：
  getListCurrentIdx(listData, param) {
    let cusIdx = 0;
    for (let i = 0; i < listData.length; i++) {
      if (listData[i]['param'] == param) {
        cusIdx = i;
        break;
      }
    }
    return cusIdx;
  }

  //当退出、Token过期时返回到登录页面：
  returnLogin() {
    this.storage.set('loginState', false);
    this.storage.remove('userinfo');
    this.storage.remove('Authorization');
    this.router.navigateByUrl('/login');
    console.log("去登录");
  }
  //下载excel文件：
  downloadExcel(data, name = "订单下载", suffix = "xlsx"): void {
    var blob = new Blob([data], { type: 'application/vnd.ms-excel    application/x-excel;charset=utf-8' });
    // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob);
    downloadElement.href = href;

    downloadElement.download = `${name}.${suffix}`;// '传染病报告卡待填报导出.xls';
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }
  //广播：
  // emitMethod(txt) {
  //   return new Promise((resolve, rejects) => {
  //     // 接收发射过来的数据
  //     this.emitService.eventEmit.subscribe((value: any) => {
  //       // this.changeDetectorRef.markForCheck();
  //       if (value == txt) {
  //         setTimeout(() => {
  //           resolve({});
  //         }, 2000);
  //       }
  //     });
  //   });
  // }



}
